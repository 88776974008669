// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-improve-it-js": () => import("./../../../src/pages/improve-it.js" /* webpackChunkName: "component---src-pages-improve-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iq-test-js": () => import("./../../../src/pages/iq-test.js" /* webpackChunkName: "component---src-pages-iq-test-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-retrieve-results-js": () => import("./../../../src/pages/retrieve-results.js" /* webpackChunkName: "component---src-pages-retrieve-results-js" */),
  "component---src-pages-tandc-js": () => import("./../../../src/pages/tandc.js" /* webpackChunkName: "component---src-pages-tandc-js" */)
}

